<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">Enable Two Factor Authentication</h1>
    <v-card>
      <v-card-text v-if="!confirmPassword">
        <p>In order for you to access the Two Factor Authentication QR code, please confirm your password.</p>
        <ConfirmPasswordForm />
      </v-card-text>
      <v-card-text v-if="confirmPassword && !confirmTwoFactor">
        <p>To gain full access to AIIU SEMS you need to activate Two Factor Authentication.</p>
        <v-alert v-if="has2fa" type="warning">
          By clicking on the <strong>Regenerate 2FA</strong> button your Two Factor Authentication codes will be
          re-generated and you will not be able to access this application with your current Two Factor Authentication
          details.
        </v-alert>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn v-if="has2fa" color="primary" @click="disableTwoFactoryAuthentication">Disable 2FA</v-btn>
          <v-btn v-if="has2fa" color="primary" @click="enableTwoFactorAuthentication">Regenerate 2FA</v-btn>
          <v-btn v-if="!has2fa" color="primary" @click="enableTwoFactorAuthentication">Activate 2FA</v-btn>
        </v-card-actions>
      </v-card-text>
      <v-card-text v-if="confirmPassword && confirmTwoFactor">
        <v-card-text v-if="qrCode">
          <h2 class="headline pb-4 mx-lg-auto">You have enabled two factor authentication</h2>
          <p>
            When two factor authentication is enabled, you will be prompted for a secure, random token during
            authentication. You may retrive this token from your phone's Google Authenticator application.
          </p>
          <h2 class="headline pb-4 mx-lg-auto">QR Code</h2>
          <p>Scan the QR code below with an authentication application, such as Google Authenticator, on your phone.</p>
          <div v-html="qrCode" class="pb-4" />
          <h2 class="headline pb-4 mx-lg-auto">Recovery Codes</h2>
          <p>Copy the following recovery codes and keep them in a safe place.</p>
          <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer> </v-data-table>
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import store from '@/store/index';
import { mapGetters } from 'vuex';
import ConfirmPasswordForm from '@/components/ConfirmPasswordForm';

export default {
  name: 'TwoFactorAuthenticationView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('auth/confirmPasswordStatus');
    next();
  },
  components: {
    ConfirmPasswordForm
  },
  methods: {
    enableTwoFactorAuthentication() {
      if (this.confirmPassword) {
        this.confirmTwoFactor = true;
        AuthService.enableTwoFactorAuthentication()
          .then(() => {
            return Promise.all([this.showQrCode()]);
          })
          .then(() => {
            this.twoFactorEnabled = true;
            this.getRecoveryCodes();
            store.dispatch('auth/getAuthUser');
          });
      }
    },
    disableTwoFactoryAuthentication() {
      const id = this.authUser.id;
      if (id) {
        AuthService.disableTwoFactorAuthentication(id).then(() => {
          store.dispatch('auth/getAuthUser');
          this.$router.go();
        });
      }
    },
    showQrCode() {
      AuthService.showQrCode().then(response => {
        this.qrCode = response.data.svg;
      });
    },
    getRecoveryCodes() {
      AuthService.twoFactorRecoveryCodes().then(response => {
        this.recoveryCodes = response.data;
      });
    }
  },
  computed: {
    ...mapGetters('auth', ['loading', 'error', 'confirmPassword', 'has2fa', 'authUser']),
    items() {
      return this.recoveryCodes.map(item => {
        return { code: item };
      });
    }
  },
  data: () => ({
    twoFactorEnabled: false,
    confirmTwoFactor: false,
    qrCode: '',
    headers: [{ text: 'Code', value: 'code' }],
    recoveryCodes: [],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Settings',
        exact: true,
        to: '/settings'
      },
      {
        text: 'Two Factor Authentication',
        disabled: true,
        exact: true,
        to: '/two-factor-authentication'
      }
    ]
  })
};
</script>
