<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="submit" autocomplete="off">
      <ValidationProvider name="password" rules="required">
        <v-text-field
          id="password"
          label="Confirm Password"
          name="password"
          v-model="password"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn type="submit" color="primary" :disabled="invalid">Verify</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  name: 'ConfirmPasswordFormView',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      code: null,
      password: ''
    };
  },
  methods: {
    async submit() {
      const payload = {
        password: this.password
      };
      this.$store.dispatch('auth/confirmPassword', payload);
    }
  },
  computed: {
    ...mapGetters('auth', ['error'])
  },
  watch: {
    error: function (error) {
      if (error.password && error.password.length) {
        this.$store.dispatch('ui/snackbarError', error.password[0] ?? 'Unable to verify');
      }
    }
  }
};
</script>
