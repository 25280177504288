import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"id":"password","label":"Confirm Password","name":"password","type":"password","autocomplete":"new-password","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})}}],null,true)}),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v("Verify")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }